import React, { useState } from 'react'
import { useFormik } from 'formik'
import axios from 'axios';
import * as yup from "yup";
import { AddingNotify } from '../../../_metronic/helpers/Toastify';

const Schema = yup.object().shape({
  email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
    .required("Veuillez saisir votre email"),
  nom: yup.string().required("Veuillez saisir votre nom"),
  telephone: yup.string()
    .required("Veuillez saisir votre numéro de téléphone")
    .matches(/^\d+$/, "Veuillez entrer un numéro de téléphone valide."),
  prenom: yup.string().required("Veuillez saisir votre prénom"),
  password: yup.string().required("Veuillez saisir votre mot de passe"),
});

const AddUser: React.FC = () => {

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
      password: "",
      role: "ROLE_ADMIN",

    },
    validationSchema: Schema,
    onSubmit: async (values) => {

      const formData = new FormData();
      formData.append('nom', values.nom);
      formData.append('prenom', values.prenom);
      formData.append('telephone', values.telephone);
      formData.append('email', values.email);
      formData.append('password', values.password);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-admin`, formData,

        );

        if (response.status === 200) {
          AddingNotify("Compte d'administrateur")
        }
        // console.log(response.data);
      } catch (error) {
        console.error(error);
      }

      // resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Administrateur</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom</label>

                <input
                  type='Nom'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='nom'
                  {...formik.getFieldProps('nom')}
                />
                {formik.touched.nom && formik.errors.nom && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nom}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Prenom</label>

                <input
                  type='Prenom'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='prenom'
                  {...formik.getFieldProps('prenom')}
                />
                {formik.touched.prenom && formik.errors.prenom && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.prenom}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Telephone</label>

                <input
                  type='Telephone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='telephone'
                  {...formik.getFieldProps('telephone')}
                />
                {formik.touched.telephone && formik.errors.telephone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.telephone}</div>
                  </div>
                )}
              </div>
            </div>


            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Email</label>

                <input
                  type='Email'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Mot de passe</label>

                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Mot de passe'
                  {...formik.getFieldProps('password')}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6 d-none'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Role</label>

                <input
                  type='role'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='ROLE ADMIN'
                  readOnly
                  {...formik.getFieldProps('role')}
                />
                {formik.touched.role && formik.errors.role && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.role}</div>
                  </div>
                )}
              </div>

            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddUser