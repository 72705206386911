import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { useParams } from 'react-router-dom';
import { UFormatDate } from '../../../_metronic/helpers/ApiRequest';
import { UpdateNotify } from '../../../_metronic/helpers/Toastify';

const Schema = Yup.object().shape({
  cpName: Yup.string().required('Ce champ est obligatoire'),
  cpDiscount: Yup.number()
    .min(1, 'La remise doit être d\'au moins 1%')
    .max(100, 'La remise ne peut pas dépasser 100%')
    .required('Ce champ est obligatoire')
    .typeError('Veuillez entrer un nombre valide'),
  cpStatus: Yup.number().oneOf([0, 1], 'Invalid value'),
  cpDateDebut: Yup.date().required('Ce champ est obligatoire'),
  cpDateFin: Yup.date().required('Ce champ est obligatoire'),
});

const UpdateCodePromo = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const apiRequest = useApiRequest()
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      cpName: '',
      cpDiscount: '',
      cpDateDebut: '',
      cpDateFin: '',
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      const data = { ...values, cpStatus: status, cpDateUpdate: new Date() };

      const formData = new FormData();
      formData.append('cpName', values.cpName);
      formData.append('cpDiscount', values.cpDiscount);
      formData.append('cpStatus', String(data.cpStatus));
      formData.append('cpDateDebut', values.cpDateDebut);
      formData.append('cpDateFin', values.cpDateFin);

      try {

        const response = await apiRequest({
          route: `code_promos/${id}`,
          method: 'PUT',
          body: data
        });

        if (response.status === 200) {
          UpdateNotify('Code Promo');
          fetchData();
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }

      resetForm();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const fetchData = useCallback(async () => {
    try {
      const response = await apiRequest({
        route: `code_promos/${id}.json`,
        method: 'GET',
      });
      setValues({
        cpName: response.data.cpName,
        cpDiscount: response.data.cpDiscount,
        cpDateDebut: UFormatDate(response.data.cpDateDebut),
        cpDateFin: UFormatDate(response.data.cpDateFin),
      });

      setStatus(response.data.cpStatus);

    } catch (error) {
      console.error(error);
    }
  }, [id, setValues]);

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier Code Promo</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Promo
                </label>
                <input
                  type='text'
                  name='cpName'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Code Promo'
                  onBlur={(event) => {
                    handleBlur(event);
                  }}
                  onChange={handleChange}
                  value={values.cpName}
                />
                {touched.cpName && errors.cpName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cpName}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Discount
                </label>
                <input
                  type='number'
                  name='cpDiscount'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Code Discount'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cpDiscount}
                />
                {touched.cpDiscount && errors.cpDiscount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cpDiscount}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Code Status
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='cpStatus'
                    id='active'
                    className='form-check-input'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 col-form-label fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='cpStatus'
                    id='desactive'
                    className='form-check-input'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 col-form-label fw-bold'>
                    Desactive
                  </label>

                  {/* {touched.cpStatus && errors.cpStatus && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.cpStatus}</div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Date de debut de promo
                </label>
                <input
                  type='date'
                  name='cpDateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de promo'
                  min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cpDateDebut}
                />
                {touched.cpDateDebut && errors.cpDateDebut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cpDateDebut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Date de fin de promo
                </label>
                <input
                  type='date'
                  name='cpDateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de promo'
                  min={values.cpDateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cpDateFin}
                  disabled={!values.cpDateDebut}

                />
                {touched.cpDateFin && errors.cpDateFin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.cpDateFin}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateCodePromo;