import React from 'react';
import { Modal } from 'react-bootstrap';

interface OrderDetailsModalProps {
  order: any;
  show: boolean;
  onHide: () => void;
}

const OrderDetailsModal: React.FC<OrderDetailsModalProps> = ({ order, show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName='order-adresse-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Liste Des Articles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Image</th>
                <th className='ps-4 min-w-300px rounded-start'>Nom Produit / Pack</th>
                <th className='ps-4 min-w-100px'>Prix</th>
                <th className='min-w-100px'>Quantité</th>
                <th className='min-w-125px'>Methode de payemet</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
                order?.orderItems.map((o: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px'>
                          <img

                            src={`${process.env.REACT_APP_API_UPLOAD}/${o.product
                              ?
                              `product_images/${o.product?.image}`
                              :
                              `pack_images/${o.pack?.image}`}`}
                            className='h-100px w-100px h-lg-100px'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        <span className="text-info">{o.product ? o.product?.tarif_regulier : o.pack?.tarif_regulier}</span> DH
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        <span className="text-info">{o.quantity}</span> {`${o.product ? 'Produit' : 'Pack'}`}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {order?.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}
                      </span>
                    </td>
                  </tr>
                ))
              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default OrderDetailsModal;