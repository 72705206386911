interface IProduit {
  id: number;
  title: string;
  description: string;
  image: string;
  dateCreate: string;
  etat: string;
  url: string;
  priorite: string;
  marque: string;
  categorie: string;
}

const produits: IProduit[] = [
  {
    id: 1,
    title: 'Product 1',
    description: 'This is the description for Product 1',
    image: 'https://picsum.photos/id/1/200/300',
    dateCreate: '2023-05-04',
    etat: 'active',
    url: '/product-1',
    priorite: 'High',
    marque: 'Brand 1',
    categorie: 'Category 1'
  },
  {
    id: 2,
    title: 'Product 2',
    description: 'This is the description for Product 2',
    image: 'https://picsum.photos/id/2/200/300',
    dateCreate: '2023-05-03',
    etat: 'inactive',
    url: '/product-2',
    priorite: 'Low',
    marque: 'Brand 2',
    categorie: 'Category 1'
  },
  {
    id: 3,
    title: 'Product 3',
    description: 'This is the description for Product 3',
    image: 'https://picsum.photos/id/3/200/300',
    dateCreate: '2023-05-02',
    etat: 'active',
    url: '/product-3',
    priorite: 'Medium',
    marque: 'Brand 1',
    categorie: 'Category 2'
  },
  {
    id: 4,
    title: 'Product 4',
    description: 'This is the description for Product 4',
    image: 'https://picsum.photos/id/4/200/300',
    dateCreate: '2023-05-01',
    etat: 'inactive',
    url: '/product-4',
    priorite: 'High',
    marque: 'Brand 2',
    categorie: 'Category 2'
  },
  {
    id: 5,
    title: 'Product 5',
    description: 'This is the description for Product 5',
    image: 'https://picsum.photos/id/5/200/300',
    dateCreate: '2023-04-30',
    etat: 'active',
    url: '/product-5',
    priorite: 'Low',
    marque: 'Brand 3',
    categorie: 'Category 3'
  }
];

export default produits;
