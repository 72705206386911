import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { DeleteNotify, UpdateNotify } from '../../../_metronic/helpers/Toastify';
import { CategorieProps } from '../../../_metronic/helpers/Props';
import { useCategoriesContext } from '../../context/CategoriesContext';
import SubCategoryItem from './SubCategoryItem';

const Schema = Yup.object().shape({
  title: Yup.string().required('Le nom de categorie est obligatoire!'),
  description: Yup.string(),
  // }).test("fileType", "Unsupported file format", (value) => {
  //   return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const UpdateCategorie: React.FC = () => {

  const [loading, setLoading] = useState(false)
  // const [imagefile, setImage] = useState<File | any>(null);
  // const [file, setFile] = useState<string>("");
  const { id } = useParams();
  const apiRequest = useApiRequest()
  const [newImage, setNewImage] = useState<File | null>(null);
  const navigate = useNavigate();
  const [categorie, setCategorie] = useState<CategorieProps>();
  const [SubExist, setSubExist] = useState(false);
  const [parentTitle, setParentTitle] = useState();
  // const [SubCategoryTitle, setSubCategoryTitle] = useState('');
  // const [SubCategoryDesc, setSubCategoryDesc] = useState('');
  // const [SubCategoryParent, setSubCategoryParent] = useState('');
  const { categories } = useCategoriesContext();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `categorie-by-id/${id}`,
        method: 'GET',
      });

      setCategorie(response.data);
      if (response.data.subCategory.length > 0) {
        setSubExist(true);
        setParentTitle(response.data.title)
      }
      // console.log(response.data.id)

      setValues({
        title: response.data.title,
        description: response.data.description !== null ? response.data.description : '',
        parent: response.data.parent,
        image: response.data.image
      });


    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      parent: "",
      image: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('imageFile', newImage ? newImage : '');

        const response = await apiRequest({
          route: `update-categorie/${id}`,
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          navigate('/categorie/categorie-list');
          UpdateNotify('Categorie');
        }

      } catch (error) {
        console.error(error);
        // Handle error, show an error message
      }

      resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  const DeleteCategorie = async (categorieId: number) => {
    try {
      const response = await apiRequest({
        route: `categories/${categorieId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Sous Categorie');
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };


  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Une Categorie</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom De Categorie</label>

                      <input
                        type='text'
                        name='title'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Nom de categorie'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.title}</div>
                        </div>
                      )}
                    </div>

                    <div className='col-lg-6 col-md-6 fv-row'>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-70px'>
                          <img
                            src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${values.image}`}
                            className='w-100 shadow'
                            alt=''
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                  <input
                    type='text'
                    name='description'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                </div>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImage ? (
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>
              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary fw-bold' disabled={loading}>
                {!loading && 'Enregistrer les modifications'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>

      </div>

      {SubExist ?

        <div className="card mb-5 mb-xl-10">
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#sub'
            aria-expanded='true'
            aria-controls='sub'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>List Des Sous-Categories</h3>
            </div>
          </div>
          <div id='sub' className='collapse show'>
            {/* <form onSubmit={subFormik.handleSubmit} noValidate className='form'> */}

            <div className='card-body border-top p-9'>

              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-125px'>Nom De Sous-Categorie</th>
                      <th className='min-w-200px'>Description</th>
                      <th className='ps-4 min-w-125px'>Parent</th>
                      <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {
                      categorie?.subCategory.map((sub, index) => {
                        return (
                          <SubCategoryItem
                            deleteSubCategorie={DeleteCategorie}
                            key={index}
                            data={sub}
                            categorieId={categorie.id}
                            className=""
                            fetchData={fetchData}
                          />
                        );
                      }
                      )}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* </form> */}
          </div>
        </div>
        : ''
      }

    </>
  )
}

export default UpdateCategorie;