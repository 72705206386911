import React, { useEffect, useState } from 'react'
// import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { formatDate, useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { DeleteNotify } from '../../../_metronic/helpers/Toastify';
import { EmailProps } from '../../../_metronic/helpers/Props';

const EmailsList: React.FC = () => {

  const apiRequest = useApiRequest()
  const [emails, setEmails] = useState<EmailProps[]>([]);

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'user_emails.json',
        method: 'GET',
      });

      setEmails(response.data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const DeleteEmail = async (emailId: number) => {
    try {
      const response = await apiRequest({
        route: `user_emails/${emailId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Email');
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Emails</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Client</th>
                <th className='ps-4 min-w-125px'>Adresse Email</th>
                <th className='min-w-200px'>Sujet</th>
                <th className='min-w-200px'>Message</th>
                <th className='min-w-150px'>Date D'envoyé</th>
                <th className='min-w-150px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {emails.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.name}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.email}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.subject}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.message}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(item.dateCreate)}
                      </span>
                    </td>

                    <td className='text-end'>
                      {
                        item.getResponse === null || item.getResponse === 0
                          ?
                          <Link to={`/email/email-response/${item.id}`} className='btn btn-primary btn-sm me-3 fw-bold'>
                            Repondre
                          </Link>
                          :
                          <span className='badge badge-light-success fs-6 p-4 me-3'>
                            Déjà répondu
                          </span>
                      }
                      <button onClick={() => DeleteEmail(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default EmailsList