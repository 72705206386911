import React, { useState, ChangeEvent } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { AddingNotify } from '../../../_metronic/helpers/Toastify';

const Schema = Yup.object().shape({
  title: Yup.string().required('Le nom de categorie est obligatoire!'),
  description: Yup.string(),
  parent: Yup.string(),
  // image: Yup.mixed().required("image is required")
  // }).test("fileType", "Unsupported file format", (value) => {
  //   return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const AddCategorie: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const { categories } = useCategoriesContext();
  const apiRequest = useApiRequest()

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      parent: ""

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('imageFile', imagefile);
      formData.append('parent', values.parent);

      try {
        const response = await apiRequest({
          route: `categories`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Categorie')
        }

      } catch (error) {
        console.error(error);
        // Handle error
      }

      resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Une Categorie</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>

          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de categorie</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de categorie'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Image</label>

                    <input
                      type="file"
                      name="image"
                      accept=".png, .jpg, .jpeg, .webp"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {

                        if (e.target.files && e.target.files[0]) {
                          setFile(URL.createObjectURL(e.target.files[0]));
                          setImage(e.target.files[0]);
                        }
                      }} />
                    {formik.values.title && imagefile === null && formik.values.parent !== "parent" && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>L'image de categorie est obligatoire!</div>
                      </div>
                    )}

                  </div>

                </div>
              </div>
            </div>


            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Parent</label>

                <select
                  // name="categorie"
                  className="form-select"
                  value={formik.values.parent}
                  onChange={(event) => {
                    formik.setFieldValue('parent', event.target.value);
                  }}
                >
                  <option value="parent">Parent</option>
                  {categories.map((categorie: any, index) => {
                    return (
                      <option key={index} value={categorie.id}>
                        {categorie.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={imagefile === null || loading ? false : false}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddCategorie