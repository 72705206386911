import React, { useEffect, useState } from 'react'
import { useApiRequest } from '../../../helpers/ApiRequest'

type Props = {
  className: string
}

const ListsWidget6: React.FC<Props> = ({ className }) => {

  const [topProductSelling, setTopProductSelling] = useState<any>([])
  const apiRequest = useApiRequest()

  const getSAlles = async () => {
    try {
      const response = await apiRequest({
        route: 'top-selling-products',
        method: 'GET',
      })

      setTopProductSelling(response.data.topSelling);

    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])


  const maxSale = topProductSelling.length > 0 ? Math.max(...topProductSelling.map((sale: any) => sale.totalPrice)) : 0

  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8 ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Produits les plus vendus</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-0'>
        {topProductSelling.map((todaySalle: any, index: number) => {
          const progressWidth =
            index === 0 ? '100%' : `${(todaySalle.totalPrice / maxSale) * 100}%`
          return (
            <div className='d-flex rounded p-5 mb-7 flex-column' key={index}>
              <div className='d-flex justify-content-between mb-2'>
                <span className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {todaySalle.title}
                </span>
                <span className='fw-bold text-info py-1'>{todaySalle.totalPrice === null || todaySalle.totalPrice === 0 ? todaySalle.totalPrice : parseFloat(todaySalle.totalPrice).toFixed(2)} DH</span>
              </div>
              <div className='progress h-8px w-100'>
                <div
                  className='progress-bar bg-primary'
                  role='progressbar'
                  style={{ width: progressWidth }}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { ListsWidget6 }