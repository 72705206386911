import React, { ChangeEvent, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import axios from 'axios';
import { AddingNotify } from '../../../_metronic/helpers/Toastify';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';

const Schema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  // image: Yup.mixed().required("image is required")
}).test("fileType", "Unsupported file format", (value) => {
  return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const AddSlider: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const apiRequest = useApiRequest()

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('imageFile', imagefile);

      try {
        const response = await apiRequest({
          route: `sliders`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Slider')
        }
        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }


      resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Slider</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>title</label>

                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='title'
                      {...formik.getFieldProps('title')}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  {/* <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                    <input
                      type='file'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='image'
                      {...formik.getFieldProps('image')}
                    />

                    {formik.touched.image && formik.errors.image && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.image}</div>
                      </div>
                    )}


                  </div> */}

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>


                    <input
                      type="file"
                      name="image"
                      // accept='image/png, image/jpeg, image/svg+xml'
                      accept=".png, .jpg, .jpeg"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      required
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // const reader = new FileReader();
                        // reader.onload=function(e){
                        //   setUrl(e.target?.result)
                        // }
                        // reader.readAsDataURL(e.target.files[0])

                        if (e.target.files && e.target.files[0]) {
                          setFile(URL.createObjectURL(e.target.files[0]));
                          setImage(e.target.files[0]);
                        }
                      }} />

                  </div>

                </div>
              </div>
            </div>


            <div className='row mb-6'>

              <div className='col-lg-12 col-md-12 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSlider
