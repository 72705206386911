import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { getTodayDate, useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { DeleteNotify } from '../../../_metronic/helpers/Toastify'
import { MarqueProps, ProductProps } from '../../../_metronic/helpers/Props'
import { RxReset } from 'react-icons/rx'
import { Loading } from '../../../_metronic/helpers/Loading';
import { useCategoriesContext } from '../../context/CategoriesContext'
import { HiOutlineRefresh } from 'react-icons/hi'
// import Pagination from '@mui/material/Pagination'
import { Pagination } from '@mui/material'
import axios from 'axios'

const ProductList: React.FC = () => {

  const [products, setProducts] = useState<ProductProps[]>([]);
  const apiRequest = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);
  const { categories } = useCategoriesContext();
  const [marques, setMarques] = useState<MarqueProps[]>([]);


  // Pagination state
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [filterTotalItems, setFilterTotalItems] = useState(0)

  // Filter state
  const [selectedCategory, setSelectedCategory] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productMarque, setProductMarque] = useState('');
  const [productSubCategory, setProductSubCategory] = useState('');
  const [inputFilter, setInputFilter] = useState(false)

  const fetchMarquesData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      console.error(error);
    }
  };


  const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedCategory(event.target.value);
  };

  const subCategories = categories.find(category => category.id === Number(selectedCategory))?.subCategory || [];


  // const fetchData = async () => {

  //   await fetch(`${process.env.REACT_APP_API_URL}/products.jsonld?page=${currentPage}&itemsPerPage=10`)
  //     .then((res) => res.json())
  //     .then((data) => {

  //       setProducts(data['hydra:member']);
  //       setTotalItems(data['hydra:totalItems']);
  //       setTotalPages(Math.ceil(totalItems / 10));
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/products.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });
      setProducts(response.data['hydra:member']);
      setTotalItems(response.data['hydra:totalItems']);
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
    } catch (error) {
      console.error(error);
    }
  };

  // const filterData = async () => {
  //   setIsFilter(true)

  //   await fetch(`${process.env.REACT_APP_API_URL}/products?title=${productTitle !== '' ? productTitle : ''}&marque=${productMarque !== '' ? productMarque : ''}&categorie=${productSubCategory !== '' ? productSubCategory : (selectedCategory !== '' ? selectedCategory : '')}&page=${filterCurrentPage}&itemsPerPage=5`)
  //     .then((res) => res.json())
  //     .then((data) => {

  //       setProducts(data['hydra:member']);
  //       setFilterTotalItems(data['hydra:totalItems']);
  //       setFilterTotalPages(Math.ceil(data['hydra:totalItems'] / 5));
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const filterData = async () => {
    setIsFilter(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/products.jsonld`, {
        params: {
          title: productTitle || '',
          marque: productMarque || '',
          categorie: productSubCategory || selectedCategory || '',
          page: filterCurrentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      setProducts(response.data['hydra:member']);
      setFilterTotalItems(response.data['hydra:totalItems']);
      setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
    } catch (error) {
      console.error(error);
    }
  };


  const DeleteProduct = async (productId: number) => {
    try {
      const response = await apiRequest({
        route: `products/${productId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Produit');
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value);
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value);
  }

  // useEffect

  useEffect(() => {
    fetchMarquesData();
  }, [])

  useEffect(() => {
    fetchData();
  }, [currentPage, totalItems])

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (productMarque !== '' || productTitle !== '' || selectedCategory !== '') {
      setInputFilter(true);
    }
  }, [productMarque, productTitle, selectedCategory])


  const resetFilter = () => {
    setProductTitle('')
    setProductMarque('')
    setSelectedCategory('')
    setProductSubCategory('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData();
  }

  return (

    <>

      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de Produit
                </label>
                <input
                  className="form-control "
                  type="search"
                  aria-label="Search"
                  placeholder='Nom de Produit'
                  value={productTitle}
                  onChange={(e) => setProductTitle(e.target.value)} />

              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Marque</label>
                <select
                  name="marque"
                  className="form-select"
                  value={productMarque}
                  onChange={(e) => setProductMarque(e.target.value)}
                >
                  <option value="">Marque</option>
                  {marques.map((marque: any, index) => {
                    return (
                      <option key={index} value={marque.id}>
                        {marque.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Categorie</label>
                <select
                  name="categorie"
                  className="form-select"
                  // value={selectedCategory}
                  // onChange={handleCategoryChange}
                  // {...formik.getFieldProps('categorie')}
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  <option value="" disabled>Categorie</option>
                  {categories.map((categorie: any, index) => {
                    return (
                      <option key={index} value={categorie.id}>
                        {categorie.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-10 col-md-10 col-form-label fw-bold fs-6'>Sous Categorie</label>
                <select
                  className="form-select"
                  onChange={(e) => setProductSubCategory(e.target.value)}
                  value={productSubCategory}
                >
                  <option value="">Sous Categorie</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                {
                  !isLoading ?
                    <>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6 '>
                      </label>
                      <button className='btn btn-light-primary me-3 fw-bold mt-4' onClick={filterData} disabled={!inputFilter} >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        Filtrer
                      </button>

                    </>

                    :
                    <Loading />
                }
              </div>

              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Produits</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
          </h3>
          <div className='card-toolbar'>
            <Link to='/product/add-product' className='btn btn-sm btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Nouveau Produit
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>Image</th>
                  <th className='ps-4 min-w-200px'>Title</th>
                  <th className='min-w-125px'>Prix</th>
                  <th className='min-w-125px'>Stock</th>
                  <th className='min-w-125px'>Categorie</th>
                  <th className='min-w-125px'>Marque</th>
                  <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {products.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-70px'>
                            <img
                              src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${item.image}`}
                              className='w-100'
                              alt=''
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.title}
                        </span>
                      </td>
                      <td>
                        <span className='text-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.tarif_regulier} DH
                        </span>
                      </td>
                      <td>
                        <span className='text-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.stock} unité
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.categorie[0].title}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.marque.title}
                        </span>
                      </td>
                      <td className='text-end'>
                        <Link to={`/product/update-product/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                          <i className='bi bi-pen-fill fs-4'></i>
                        </Link>
                        <button onClick={() => DeleteProduct(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {
          isFilter
            ? <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />
        }
      </div>

    </>
  )
}

export default ProductList