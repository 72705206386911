import React, { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { QuillModules, useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { MarqueProps } from '../../../_metronic/helpers/Props'
import { AddingNotify } from '../../../_metronic/helpers/Toastify'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ProductsStyle.css'

// const categorieOptions = categorie.map((option) => option.categorie);
// const file_size = 30 * 1024 * 1024;

const Schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
  marque: Yup.string(),
  categorie: Yup.string(),
  //   image: Yup.mixed().required("image is required")
  // }).test("fileType", "Unsupported file format", (value) => {
  //   return value && ["image/jpeg", "image/png", "image/svg"].includes(value.type);
})

const AddProduct: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<File[]>([]);
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const { categories } = useCategoriesContext();
  const [selectedCategory, setSelectedCategory] = useState('');
  const apiRequest = useApiRequest()
  const [marques, setMarques] = useState<MarqueProps[]>([]);

  const [descriptionCourte, setDescriptionCourte] = useState('');
  const [description, setDescription] = useState('');


  //   const [editorState, setEditorState] = React.useState(() =>
  //   EditorState.createEmpty()
  // );

  // const editor = React.useRef<any | null>(null);
  // function focusEditor() {
  //   if (editor.current) {
  //       editor.current.focus();
  //   }
  // }

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])

  const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedCategory(event.target.value);
  };

  const subCategories = categories.find(category => category.id === Number(selectedCategory))?.subCategory || [];


  const formik = useFormik({
    initialValues: {
      title: "",
      description_courte: "",
      description: "",
      categorie: "",
      subcategorie: "",
      marque: "",
      tarif_regulier: "",
      montant_de_stock_faible: "",
      stock: "",
      en_stock: "",
      // date_de_debut_de_promo: "",
      // date_de_fin_de_promo: "",
      etat_de_la_tva: "",
      classe_de_tva: "",
      poids_kg: "",
      longueur_cm: "",
      largeur_cm: "",
      hauteur_cm: "",
      autoriser_les_avis_clients: "",
      // limite_de_telechargement: "",
      // jours_dexpiration_du_telechargement: "",
      video_url: "",
    },

    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("productImages[]", files[i]);
      }

      // console.log(values)
      // console.log(imagefile)
      // console.log(files)

      formData.append('title', values.title);
      // formData.append('description_courte', descriptionCourte);
      formData.append('description_courte', values.description_courte);
      // formData.append('description', description);
      formData.append('description', "@ltp@gt@ltbr@gt@lt/p@gt@ltdiv class='quill-better-table-wrapper'@gt@lttable class='quill-better-table'style='width: 100px;'@gt@ltcolgroup@gt@ltcol width='100'@gt@lt/colgroup@gt@lttbody@gt@lttr data-row='row-kag2'@gt@lttd data-row='row-kag2'rowspan='1'colspan='1'@gt@ltp class='qlbt-cell-line'data-row='row-kag2'data-cell='cell-4e73'data-rowspan='1'data-colspan='1'@gthh@lt/p@gt@lt/td@gt@lt/tr@gt@lttr data-row='row-te6o'@gt@lttd data-row='row-te6o'rowspan='1'colspan='1'@gt@ltp class='qlbt-cell-line'data-row='row-te6o'data-cell='cell-3npu'data-rowspan='1'data-colspan='1'@gthh@lt/p@gt@lt/td@gt@lt/tr@gt@lttr data-row='row-hulg'@gt@lttd data-row='row-hulg'rowspan='1'colspan='1'@gt@ltp class='qlbt-cell-line'data-row='row-hulg'data-cell='cell-aebt'data-rowspan='1'data-colspan='1'@gthh@lt/p@gt@lt/td@gt@lt/tr@gt@lt/tbody@gt@lt/table@gt@lt/div@gt@ltp@gt@ltbr@gt@lt/p@gt");
      // formData.append('description', values.description);
      formData.append('subcategorie', values.subcategorie);
      formData.append('tarif_regulier', values.tarif_regulier);
      // formData.append('date_de_debut_de_promo', values.date_de_debut_de_promo);
      // formData.append('date_de_fin_de_promo', values.date_de_fin_de_promo);
      formData.append('stock', values.stock);
      formData.append('montant_de_stock_faible', values.montant_de_stock_faible);
      formData.append('poids_kg', values.poids_kg);
      formData.append('longueur_cm', values.longueur_cm);
      formData.append('largeur_cm', values.largeur_cm);
      formData.append('hauteur_cm', values.hauteur_cm);
      // formData.append('limite_de_telechargement', values.limite_de_telechargement);
      // formData.append('jours_dexpiration_du_telechargement', values.jours_dexpiration_du_telechargement);
      formData.append('imageFile', imagefile);
      formData.append('category', selectedCategory);
      formData.append('marque', values.marque);
      formData.append('video_url', values.video_url);

      try {
        const response = await apiRequest({
          route: `products`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Produit')
          console.log(formData);

        }
        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }
      // console.log(formData);


      // resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Produit</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nom de produit'
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>


            </div>


            <div className="row mb-6">
              <div className="col-lg-12 col-md-12 fv-row">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                  rows={6}
                  placeholder='description'
                  {...formik.getFieldProps('description')}

                ></textarea>


                {/* <ReactQuill  
      className="react-quill-editor" 
      theme="snow" 
      value={description} 
      onChange={setDescription} 
      modules={QuillModules} 
      /> */}
              </div>
            </div>

            <div className="row mb-6">
              <div className="col-lg-12 col-md-12 fv-row">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description Courte</label>

                <textarea
                  className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                  rows={6}
                  placeholder='description_courte'
                  {...formik.getFieldProps('description_courte')}

                ></textarea>


                {/* <ReactQuill  
      className="react-quill-editor" 
      theme="snow" 
      value={descriptionCourte} 
      onChange={setDescriptionCourte} 
      modules={QuillModules} 
      /> */}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Marque</label>
                <select
                  // name="marque"
                  className="form-select"
                  {...formik.getFieldProps('marque')}
                >
                  <option value="">Marque</option>
                  {marques.map((marque: any, index) => {
                    return (
                      <option key={index} value={marque.id}>
                        {marque.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Categorie</label>
                <select
                  name="categorie"
                  className="form-select"
                  // value={selectedCategory}
                  // onChange={handleCategoryChange}
                  // {...formik.getFieldProps('categorie')}
                  onChange={handleCategoryChange}
                  value={selectedCategory}
                >
                  <option value="" disabled>Categorie</option>
                  {categories.map((categorie: any, index) => {
                    return (
                      <option key={index} value={categorie.id}>
                        {categorie.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Sous Categorie</label>
                <select
                  // name="categorie"
                  className="form-select"
                  {...formik.getFieldProps('subcategorie')}
                // onChange={handleCategoryChange}
                // value={selectedCategory}
                >
                  <option value="">Sous Categorie</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prix de produit</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Prix'
                  {...formik.getFieldProps('tarif_regulier')}
                />
                {formik.touched.tarif_regulier && formik.errors.tarif_regulier && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.tarif_regulier}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Montant de stock faible</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Montant de stock faible'
                  {...formik.getFieldProps('montant_de_stock_faible')}
                />
                {formik.touched.montant_de_stock_faible && formik.errors.montant_de_stock_faible && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.montant_de_stock_faible}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Stock</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Stock'
                  {...formik.getFieldProps('stock')}
                />
                {formik.touched.stock && formik.errors.stock && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.stock}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Poids Kg</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Poids Kg'
                  {...formik.getFieldProps('poids_kg')}
                />
                {formik.touched.poids_kg && formik.errors.poids_kg && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.poids_kg}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Longueur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Longueur cm'
                  {...formik.getFieldProps('longueur_cm')}
                />
                {formik.touched.longueur_cm && formik.errors.longueur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.longueur_cm}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Largeur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Largeur cm'
                  {...formik.getFieldProps('largeur_cm')}
                />
                {formik.touched.largeur_cm && formik.errors.largeur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.largeur_cm}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-3 col-md-6 fv-row'>
                <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Hauteur cm</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Hauteur cm'
                  {...formik.getFieldProps('hauteur_cm')}
                />
                {formik.touched.hauteur_cm && formik.errors.hauteur_cm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.hauteur_cm}</div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Date de debut de promo</label>
                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de promo'
                  // min={new Date().toISOString().split('T')[0]}
                  {...formik.getFieldProps('date_de_debut_de_promo')}
                />
                {formik.touched.date_de_debut_de_promo && formik.errors.date_de_debut_de_promo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.date_de_debut_de_promo}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Date de fin de promo</label>

                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de promo'
                  // min={new Date().toISOString().split('T')[0]}
                  {...formik.getFieldProps('date_de_fin_de_promo')}
                />
                {formik.touched.date_de_fin_de_promo && formik.errors.date_de_fin_de_promo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.date_de_fin_de_promo}</div>
                  </div>
                )}
              </div>
            </div> */}

            {/* <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Limite de_telechargement</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='LimLte de telechargement'
                  {...formik.getFieldProps('limite_de_telechargement')}
                />
                {formik.touched.limite_de_telechargement && formik.errors.limite_de_telechargement && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.limite_de_telechargement}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Jours dexpiration du telechargement</label>

                <input
                  type='date'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Jours dexpiration du telechargement'
                  {...formik.getFieldProps('jours_dexpiration_du_telechargement')}
                />
                {formik.touched.jours_dexpiration_du_telechargement && formik.errors.jours_dexpiration_du_telechargement && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.jours_dexpiration_du_telechargement}</div>
                  </div>
                )}
              </div>
            </div> */}

            <div className='row mb-6'>

              {/* Images */}

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Miniature de produit</label>

                <input
                  type="file"
                  name="image"
                  // accept='image/png, image/jpeg, image/svg+xml'
                  accept=".png, .jpg, .jpeg"
                  required
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    // const reader = new FileReader();
                    // reader.onload=function(e){
                    //   setUrl(e.target?.result)
                    // }
                    // reader.readAsDataURL(e.target.files[0])

                    if (e.target.files && e.target.files[0]) {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setImage(e.target.files[0]);
                    }
                  }} />
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Autre Images</label>

                <input
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const { files } = e.target;
                    if (files) {
                      setFiles(Array.from(files));
                    }
                  }}
                  type="file"
                  name="productImages"
                  accept=".png, .jpg, .jpeg"
                  className='form-control form-control-lg form-control-solid'
                  multiple
                />
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Lien de Video</label>

                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Lien du Video'
                  {...formik.getFieldProps('video_url')}
                />
                {formik.touched.video_url && formik.errors.video_url && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.video_url}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddProduct
