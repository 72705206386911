import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { formatDate } from '../../../_metronic/helpers/ApiRequest';
import { DeleteNotify } from '../../../_metronic/helpers/Toastify';
import { PromotionProps } from '../../../_metronic/helpers/Props';

const PromotionList: React.FC = () => {
  const apiRequest = useApiRequest()
  const [promotion, setpromotion] = useState<PromotionProps[]>([]);

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'promotions.json',
        method: 'GET',
      });

      setpromotion(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [])


  const DeletePromotion = async (promotion: number) => {
    try {
      const response = await apiRequest({
        route: `promotions/${promotion}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Promotion');
        fetchData();
      }


    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Promotions</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        <div className='card-toolbar'>
          <Link to='/promotion/add-promotion' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Promotion
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Description</th>
                <th className='ps-4 min-w-125px'>Remise</th>
                <th className='min-w-200px'>Date Debut</th>
                <th className='min-w-200px'>Date Fin</th>
                <th className='min-w-200px'>Status</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {promotion.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.description}
                      </span>
                    </td>

                    <td>
                      <span className='text-info fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.discount} %
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(item.dateDebut)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(item.dateFin)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.status === 1 ?
                          <span className='badge badge-light-success fs-6 px-4'>
                            Active
                          </span>

                          :
                          <span className='badge badge-light-warning fs-6 px-4'>
                            Desactive
                          </span>

                        }
                      </span>
                    </td>
                    <td className='text-end'>
                      <Link to={`/promotion/update-promotion/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeletePromotion(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default PromotionList