import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { toast } from 'react-toastify';
import { formatDate } from '../../../_metronic/helpers/ApiRequest';
import { DeleteNotify } from '../../../_metronic/helpers/Toastify';

interface CodePromoProps {
  id: number;
  cp_name: string,
  cp_discount: number,
  cp_status: number,
  cp_date_debut: string,
  cp_date_fin: string,
}

const CodePromoList: React.FC = () => {
  const apiRequest = useApiRequest()
  const [codePromo, setCodePromo] = useState<CodePromoProps[]>([]);

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'code_promos.json',
        method: 'GET',
      });

      setCodePromo(response.data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const DeleteCodePromo = async (codepromo: number) => {
    try {
      const response = await apiRequest({
        route: `code_promos/${codepromo}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Code Promo');
        fetchData();
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Code Promo</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        <div className='card-toolbar'>
          <Link to='/codepromo/add-codepromo' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Code Promo
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Code Promo</th>
                <th className='ps-4 min-w-125px'>Discount</th>
                <th className='min-w-200px'>Date Debut</th>
                <th className='min-w-200px'>Date Fin</th>
                <th className='min-w-200px'>Status</th>
                <th className='min-w-100px text-end rounded-end pe-5'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {codePromo.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.cp_name}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.cp_discount} %
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(item.cp_date_debut)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {formatDate(item.cp_date_fin)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.cp_status === 1 ?
                          <span className='badge badge-light-success fs-6 px-4'>
                            Active
                          </span>

                          :
                          <span className='badge badge-light-warning fs-6 px-4'>
                            Desactive
                          </span>

                        }
                      </span>
                    </td>
                    <td className='text-end'>
                      <Link to={`/codepromo/update-codepromo/${item.id}`} className='btn btn-icon btn-light-primary btn-sm me-3'>
                        <i className='bi bi-pen-fill fs-4'></i>
                      </Link>
                      <button onClick={() => DeleteCodePromo(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                        <i className='bi bi-trash-fill fs-4'></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default CodePromoList
