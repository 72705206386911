import React from 'react';
import { Modal } from 'react-bootstrap';

interface OrderAdressesModalProps {
  order: any;
  show: boolean;
  onHide: () => void;
}

const OrderAdressesModal: React.FC<OrderAdressesModalProps> = ({ order, show, onHide }) => {

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className='fs-3'>Adresses De Commande</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className='text-info mb-5'>Adresse de facturation :</h3>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Nom et Prenom :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.nom} ${order?.facturationAdresse.prenom}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Telephone :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.telephone}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Adresse Email :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.email}`}</span></p>
        {order?.facturationAdresse.entreprise !== "" ? <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Nom de l'entreprise :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.entreprise}`}</span></p> : ''}
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>N° et Nom de rue  :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.rueInfo}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Ville :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.ville}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Region :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.region}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Code Postal :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.codepostal}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Pays :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.pays}`}</span>,</p>
        {order?.facturationAdresse.apartement !== "" ? <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Apartement :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.apartement}`}</span></p> : ''}

        <hr className='hr-tag' />

        <h3 className='text-info mb-5'>Adresse de livraison :</h3>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Nom et Prenom :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.nom} ${order?.livraisonAdresse.prenom}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Telephone :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.telephone}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Adresse Email :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.email}`}</span></p>
        {order?.livraisonAdresse.entreprise !== "" ? <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Nom de l'entreprise :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.entreprise}`}</span></p> : ''}
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>N° et Nom de rue  :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.rueInfo}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Ville :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.ville}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Region :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.region}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Code Postal :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.codepostal}`}</span></p>
        <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Pays :</span> <span className='fs-5 fw-bold'>{`${order?.livraisonAdresse.pays}`}</span>,</p>
        {order?.facturationAdresse.apartement !== "" ? <p><span className='ms-5 fs-4 min-w-150px d-inline-block'>Apartement :</span> <span className='fs-5 fw-bold'>{`${order?.facturationAdresse.apartement}`}</span></p> : ''}


      </Modal.Body>
    </Modal>
  );
};

export default OrderAdressesModal;