import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { UpdateNotify } from '../../../_metronic/helpers/Toastify';

const Schema = Yup.object().shape({
  title: Yup.string().required('Le nom de marque est obligatoire!'),
  description: Yup.string(),
})

const UpdateSlider: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const { id } = useParams();
  const apiRequest = useApiRequest()
  const [newImage, setNewImage] = useState<File | null>(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `sliders/${id}.json`,
        method: 'GET',
      });

      setValues({
        title: response.data.title,
        description: response.data.description,
        image: response.data.image
      })

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      image: "",

    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      try {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('imageFile', newImage ? newImage : '');

        const response = await apiRequest({
          route: `update-slider/${id}`,
          method: 'POST',
          body: formData,
        });

        if (response.status === 200) {
          navigate('/slider/slider-list');
          UpdateNotify('Slider');
        }

      } catch (error) {
        console.error(error);
        // Handle error, show an error message
      }

      resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(e.target.files[0]);
    }
  };


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Modifier Un Slider</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>

            <div className='row mb-6'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nom de Slider</label>

                    <input
                      type='text'
                      name='title'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nom de marque'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 col-md-6 fv-row'>
                    <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ancienne image</label>

                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-70px'>
                        <img
                          src={`${process.env.REACT_APP_API_UPLOAD}/slider_images/${values.image}`}
                          className='w-100 shadow'
                          alt=''
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <input
                  type='text'
                  name='description'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Description'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
              </div>

              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                <input
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={handleImageChange} />

                {newImage ? (
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-70px'>
                      <img src={URL.createObjectURL(newImage)} alt='' className='w-100 shadow mt-2' />
                    </div>
                  </div>
                ) : (
                  ''
                )}

              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UpdateSlider