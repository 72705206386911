import React, { useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { Loading } from "../../../_metronic/helpers/Loading";
import { useApiRequest } from "../../../_metronic/helpers/ApiRequest";
import { UpdateNotify } from "../../../_metronic/helpers/Toastify";
import { useCategoriesContext } from "../../context/CategoriesContext";

type Props = {
  className: string;
  data: any;
  categorieId: number;
  fetchData: any;
  deleteSubCategorie: (id: number) => void;
};

const SubCategoryItem: React.FC<Props> = ({
  className,
  data,
  categorieId,
  fetchData,
  deleteSubCategorie,
}) => {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState<string>(data.description === null ? '' : data.description);
  const [SubCategoryParent, setSubCategoryParent] = useState(data.id);
  const [SubCategory, setSubCategory] = useState(data.id);
  const { categories } = useCategoriesContext();

  const [subId, setSubId] = useState(data.id);
  const [loading, setLoading] = useState(false);
  const body = {
    title: title,
    description: description,
    parent: SubCategoryParent,
  };
  const apiRequest = useApiRequest()


  const updateCategorie = async (id: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('title', body["title"]);
    formData.append('description', body["description"]);
    let parentId = body["parent"] === SubCategory ? String(categorieId) : body["parent"];

    formData.append('parent', parentId);

    try {
      const response = await apiRequest({
        route: `update-subcategorie/${id}`,
        method: 'POST',
        body: formData,
      });


      if (response.status === 200) {
        setLoading(false);
        fetchData();
        UpdateNotify('Sous Categorie');
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <tr key={data.key}>
      <td>

        {/* <input className="d-none" type="text" value={id} /> */}

        <input
          className='form-control form-control-lg form-control-solid'
          placeholder='Nom de categorie'
          type="text"
          name="title"
          value={title}
          onChange={(e) => { setTitle(e.target.value) }}
        />
      </td>
      <td>
        <input
          className='form-control form-control-lg form-control-solid'
          placeholder='Description'
          name="description"
          value={description ? description : ''}
          onChange={(e) => setDescription(e.target.value)}
        />
      </td>
      <td>
        <select
          name='parent'
          className="form-select"
          // value={sub.parent_id}
          value={SubCategoryParent}
          onChange={(e) => setSubCategoryParent(e.target.value)}
        >
          {/* <option value={SubCategoryParent}>{SubCategoryParent}</option> */}
          {categories.map((categorie: any, index) => {
            return (
              <option key={index} value={categorie.id}>
                {categorie.title}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <button
            type={"submit"}
            title="save"
            className="btn btn-icon btn-light-primary btn-sm me-2"
            onClick={() => updateCategorie(subId)}
          >
            {loading ? (
              <Loading />
            ) : (
              <KTSVG
                path="/media/icons/duotune/general/gen005.svg"
                className="svg-icon-3"
              />
            )}
          </button>

          <button onClick={() => deleteSubCategorie(subId)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='bi bi-trash-fill fs-4'></i>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default SubCategoryItem;