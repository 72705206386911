import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { toast } from 'react-toastify';
import { formatDate } from '../../../_metronic/helpers/ApiRequest';
import { OrderProps } from '../../../_metronic/helpers/Props';
import OrderAdressesModal from './OrderAdressesModal';
import OrderDetailsModal from './OrderDetailsModal';
// import { RxReset } from 'react-icons/rx'
import { HiOutlineRefresh } from 'react-icons/hi'
import { KTSVG } from '../../../_metronic/helpers';
import { Loading } from '../../../_metronic/helpers/Loading';
import { DeleteNotify } from '../../../_metronic/helpers/Toastify';
import { Pagination } from '@mui/material'
import axios from 'axios';

const OrderList: React.FC = () => {
  const apiRequest = useApiRequest()
  const [orders, setOrders] = useState<OrderProps[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [status, setStatus] = useState<null | string>(null);
  const [orderDate, setOrdersDate] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  // const [productsToUpdate, setProductsToUpdate] = useState<Array<{ id: number; quantity: number }>>([]);

  // Pagination state
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [filterCurrentPage, setFilterCurrentPage] = useState(1)
  const [filterTotalPages, setFilterTotalPages] = useState(1)
  const [filterTotalItems, setFilterTotalItems] = useState(0)
  const [inputFilter, setInputFilter] = useState(false)

  // const initialOrderState = orders.map(order => ({ ...order, isLoading: false }));
  // const [ordersWithLoading, setOrdersWithLoading] = useState(initialOrderState);

  const initialOrderState = orders.map(order => ({
    ...order,
    isLoadingValidate: false,
    isLoadingAnnuler: false,
  }));
  const [ordersWithLoading, setOrdersWithLoading] = useState(initialOrderState);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders.jsonld`, {
        params: {
          page: currentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      const updatedOrders = response.data['hydra:member'].map((order: any) => ({ ...order, isLoading: false }));

      // setOrders(response.data['hydra:member']);
      setOrdersWithLoading(updatedOrders);
      setTotalItems(response.data['hydra:totalItems']);
      setTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [currentPage, totalItems])

  useEffect(() => {
    if (isFilter) {
      filterData();
    }
  }, [filterCurrentPage])

  useEffect(() => {
    if (status !== null || fullName !== '' || orderDate !== '') {
      setInputFilter(true);
    }
  }, [status, fullName, orderDate])


  const DeleteOrder = async (order: number) => {
    try {
      const response = await apiRequest({
        route: `orders/${order}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Commande');
        fetchData();

      }


    } catch (error) {
      console.error(error);
    }
  };

  const changeOrderStatus = async (orderId: number, status: string) => {
    try {
      // setIsLoading(true)
      const data = { orderStatus: status };

      const response = await apiRequest({
        route: `orders/${orderId}`,
        method: 'PUT',
        body: data
      });

      if (response.status === 200) {
        if (status === 'validate') {
          // setIsLoading(false)
          fetchData();
          toast.success("Commande bien validé");
        }
        if (status === 'annuler') {
          // setIsLoading(false)
          fetchData();
          toast.success("Commande bien annuler");
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  function handlePageChange(event: any, value: any) {
    setCurrentPage(value);
  }

  function handleFilterPageChange(event: any, value: any) {
    setFilterCurrentPage(value);
  }

  const showLocation = (index: number) => {
    setSelectedOrder(ordersWithLoading[index]);
    setShowModal(true);
  };

  const showDetails = (index: number) => {
    setSelectedOrder(ordersWithLoading[index]);
    setShowDetailsModal(true);
  };

  const hideLocationModal = () => {
    setShowModal(false);
  };

  const hideDetailsModal = () => {
    setShowDetailsModal(false);
  };

  // route: `filtered-orders?dateCreate=${orderDate}&status=${status}&fullName=${fullName}`,
  // ? `filtered-ordersx?dateCreate=${orderDate}&status=${status}&fullName=${fullName !== '' ? fullName : ''}`

  const route = status !== null
    ? `filtered-orders?dateCreate=${orderDate}&orderStatus=${status}&fullName=${fullName}&page=${filterCurrentPage}&itemsPerPage=10`
    :
    `filtered-orderby?dateCreate=${orderDate}&fullName=${fullName}&page=${currentPage}&itemsPerPage=10`;

  const filterData = async () => {
    setIsFilterLoading(true)
    setIsFilter(true)
    try {
      const response = await apiRequest({
        route: route,
        method: 'GET',
      });

      if (response.status === 200) {
        setOrdersWithLoading(response.data)
        setIsFilterLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterDatax = async () => {
    setIsFilter(true);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders.jsonld`, {
        params: {
          'user.nom': fullName || '',
          status: status || '',
          dateCreate: orderDate || '',
          page: filterCurrentPage,
          itemsPerPage: 10
        },
        headers: {
          Accept: 'application/ld+json'
        }
      });

      setOrdersWithLoading(response.data['hydra:member']);
      // setOrders(response.data['hydra:member']);
      setFilterTotalItems(response.data['hydra:totalItems']);
      setFilterTotalPages(Math.ceil(response.data['hydra:totalItems'] / 10));
    } catch (error) {
      console.error(error);
    }
  };

  const resetFilter = () => {
    setStatus(null);
    setOrdersDate('')
    setFullName('')
    setIsFilter(false)
    setInputFilter(false)
    setCurrentPage(1)
    setFilterCurrentPage(1)
    fetchData();
    // setIsLoading(false)
    // setOrdersWithLoading(null)
  }

  const updateStock = async (orderId: number) => {
    const order = ordersWithLoading.find((item) => item.id === orderId);
    const data: Array<{ id: number; quantity: number }> = [];

    if (order) {
      order.orderItems.forEach((item: any) => {
        if (item.product) {
          data.push({ id: item.product.id, quantity: item.quantity });
        }
      });

      const response = await apiRequest({
        route: `update-stock`,
        method: 'POST',
        body: data
      });

    }
  }


  return (
    <>

      {/* Start Filter Card */}
      <div className={`card mb-4 py-5`}>

        <div className='card-body py-3'>
          <div className="container">
            <div className="row justify-content-start align-items-center">

              {/* Start Client Name */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Nom de client
                </label>
                <input className="form-control " type="search" aria-label="Search" placeholder='Nom de Client' value={fullName} onChange={(e) => setFullName(e.target.value)} />

              </div>
              {/* End Client Name */}

              {/* Start Order Date */}
              <div className="col-md-6 col-lg-6 col-xl-3 col-12">
                <label className='col-lg-8 col-md-8 col-form-label fw-bold fs-6'>
                  Date de commande
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de pack'
                  onChange={(e) => setOrdersDate(e.target.value)}
                  value={orderDate}
                />
              </div>
              {/* End Order Date */}

              {/* Start Status */}
              <div className="col-md-6 col-lg-6 col-xl-4 col-12">
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Statut de commande
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>

                  {/* Validate */}

                  <input
                    type='checkbox'
                    name='status'
                    id='validate'
                    className='form-check-input scale-1'
                    checked={status === 'validate'}
                    onChange={() => setStatus('validate')}
                    value={'validate'}
                  />
                  <label htmlFor='validate' className='ms-3 col-form-label fw-bold fs-5'>
                    Validé
                  </label>

                  {/* Pending */}

                  <input
                    type='checkbox'
                    name='status'
                    id='pending'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'pending'}
                    onChange={() => setStatus('pending')}
                    value={'pending'}
                  />
                  <label htmlFor='pending' className='ms-3 col-form-label fw-bold fs-5'>
                    En cours
                  </label>


                  {/* Annuler */}

                  <input
                    type='checkbox'
                    name='status'
                    id='annuler'
                    className='form-check-input ms-4 scale-1'
                    checked={status === 'annuler'}
                    onChange={() => setStatus('annuler')}
                    value={'annuler'}
                  />
                  <label htmlFor='annuler' className='ms-3 col-form-label fw-bold fs-5'>
                    Annuler
                  </label>

                </div>
              </div>
              {/* End Status */}

              {/* Start Apply Filter */}
              <div className="col-md-6 col-lg-6 col-xl-2 col-12">
                {
                  !isFilterLoading ?
                    <>
                      <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                      </label>
                      <button className='btn btn-light-primary me-3 fw-bold' onClick={filterData} disabled={!inputFilter} >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                        Filtrer
                      </button>

                    </>

                    :
                    <Loading />
                }
              </div>
              {/* End Apply Filter */}

              {/* Start Reset Filter */}
              <div className="reset-filter ">
                <div className="ico" onClick={resetFilter}>
                  <HiOutlineRefresh className='filter-icon sshadow' />
                </div>
              </div>
              {/* End Reset Filter */}

            </div>
          </div>
        </div>

      </div>
      {/* Ens Filter Card */}

      {/* Start Orders List Card */}
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste Des Commandes</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Client</th>
                  <th className='ps-4 min-w-125px'>Prix Total</th>
                  <th className='min-w-150px'>Date De Commande</th>
                  <th className='min-w-150px'>Nombre Des Articles</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-300px text-center rounded-end pe-5'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {ordersWithLoading.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {`${item.user.nom} ${item.user.prenom}`}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          <span className="text-info">{item.totalPrice}</span> DH
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatDate(item.dateCreate)}
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          <span className='text-info'>{item.itemsQuantity}</span> Produit
                        </span>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item.orderStatus === 'pending' ?
                            <span className='badge badge-light-primary fs-6 fw-bold px-4'>
                              En cours
                            </span>
                            :
                            item.orderStatus === 'validate'
                              ?
                              <span className='badge badge-light-success fs-6 fw-bold px-4'>
                                Validé
                              </span>
                              :
                              <span className='badge badge-light-warning fs-6 fw-bold px-4'>
                                Annuler
                              </span>
                          }
                        </span>
                      </td>
                      <td className='text-end'>

                        {
                          !item.isLoadingValidate ?
                            <button
                              onClick={() => {
                                changeOrderStatus(item.id, 'validate')
                                updateStock(item.id)
                                const updatedOrders = [...ordersWithLoading];
                                updatedOrders[index].isLoadingValidate = true;
                                setOrdersWithLoading(updatedOrders);
                              }}
                              className='btn btn-success btn-sm me-3 fw-bold'
                              disabled={item.orderStatus === 'validate'}

                            >
                              Valider
                            </button>
                            :
                            <button className='btn btn-sm me-3 fw-bold'>
                              <Loading />
                            </button>
                        }

                        {!item.isLoadingAnnuler ?
                          <button
                            onClick={() => {
                              changeOrderStatus(item.id, 'annuler')
                              const updatedOrders = [...ordersWithLoading];
                              updatedOrders[index].isLoadingAnnuler = true;
                              setOrdersWithLoading(updatedOrders);
                            }}
                            className='btn btn-warning btn-sm me-3 fw-bold'
                            disabled={item.orderStatus === 'annuler'}
                          >
                            Annuler
                          </button>
                          :
                          <button className='btn btn-sm me-3 fw-bold'>
                            <Loading />
                          </button>
                        }

                        <button onClick={() => showDetails(index)} className='btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </button>

                        <Link to={`/order/order-details/${item.id}`} className='d-none btn btn-icon btn-light-info btn-sm  me-3'>
                          <i className='bi bi-eye-fill fs-4'></i>
                        </Link>

                        <button onClick={() => showLocation(index)} className='btn btn-icon btn-light-primary btn-sm  me-3'>
                          <i className='bi bi-geo-alt fs-4'></i>
                        </button>

                        <button onClick={() => DeleteOrder(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='bi bi-trash-fill fs-4'></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {
          isFilter
          ?
            <Pagination
              count={filterTotalPages}
              page={filterCurrentPage}
              color='primary'
              onChange={handleFilterPageChange}
              className='d-flex justify-content-center'
            />
            :
            <Pagination
              count={totalPages}
              page={currentPage}
              color='primary'
              onChange={handlePageChange}
              className='d-flex justify-content-center'
            />

        }
        <OrderAdressesModal order={selectedOrder} show={showModal} onHide={hideLocationModal} />
        <OrderDetailsModal order={selectedOrder} show={showDetailsModal} onHide={hideDetailsModal} />
      </div>
      {/* End Orders List Card */}

    </>
  )
}

export default OrderList