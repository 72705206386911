import React, { useEffect, useState } from 'react';
// import { KTSVG } from '../../../_metronic/helpers';
import { useParams } from 'react-router-dom';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import { toast } from 'react-toastify';
// import { formatDate } from '../../../_metronic/helpers/ApiRequest';
import { OrderProps } from '../../../_metronic/helpers/Props';

const OrderItems: React.FC = () => {
  const apiRequest = useApiRequest();
  const [order, setOrder] = useState<OrderProps[]>([]);
  const { id } = useParams();

  // const notifySuccess = () => {
  //   toast.success('Commande bien Supprimé');
  // };

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: 'orders.json',
        method: 'GET',
      });

      setOrder(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let filteredData = order.filter((item) => item.id === Number(id));

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste Des Articles</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <Link to='/order/add-order' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Nouveau Commandes
          </Link>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-125px rounded-start'>Image</th>
                <th className='ps-4 min-w-125px rounded-start'>Nom Produit / Pack</th>
                <th className='ps-4 min-w-125px'>Prix</th>
                <th className='min-w-200px'>Quantité</th>
                <th className='min-w-200px'>Methode de payemet</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredData.map((item) =>
                item.orderItems.map((o, index) => (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px'>
                          <img

                            src={`${process.env.REACT_APP_API_UPLOAD}/${o.product
                              ?
                              `product_images/${o.product?.image}`
                              :
                              `pack_images/${o.pack?.image}`}`}
                            className='h-100px w-100px h-lg-100px'
                            alt=''
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {`${o.product ? o.product?.title : o.pack?.title}`}
                      </span>
                    </td>

                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {o.product ? o.product?.tarif_regulier : o.pack?.tarif_regulier} DH
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {o.quantity}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item.paymentMethod === 'livraison' ? 'À la livraison' : 'Par Carte bancaire'}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export default OrderItems;