import React, { ChangeEvent, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCategoriesContext } from '../../context/CategoriesContext'
import { QuillModules, useApiRequest } from '../../../_metronic/helpers/ApiRequest'
import { MarqueProps, ProductProps } from '../../../_metronic/helpers/Props'
import { DeleteNotify, NoImageSelectedNotify, UpdateNotify } from '../../../_metronic/helpers/Toastify'
import { useParams } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ProductsStyle.css'


const Schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string(),
  marque: Yup.string(),
  categorie: Yup.string(),
})

const UpdateProduct: React.FC = () => {

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<File[]>([]);
  const [newImagefile, setNewImageFile] = useState<File | any>(null);
  const [newImage, setNewImage] = useState<string>("");
  const [newMultiImage, setNewMultiImage] = useState<File | any>(null);
  const [multiImagefile, setMultiImageFile] = useState<string>("");
  const { categories } = useCategoriesContext();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [products, setProducts] = useState<ProductProps>()
  const { id } = useParams()
  const [multiImages, setMultiImages] = useState<File[]>([]);
  const [descriptionCourte, setDescriptionCourte] = useState('');
  const [description, setDescription] = useState('');


  const apiRequest = useApiRequest()
  const [marques, setMarques] = useState<MarqueProps[]>([]);

  const fetchMarquesData = async () => {
    try {
      const response = await apiRequest({
        route: 'marques.json',
        method: 'GET',
      });

      setMarques(response.data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchMarquesData();
  }, [])

  const handleCategoryChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSelectedCategory(event.target.value);
    console.log(event.target.value);

  };



  const subCategories = categories.find(category => category.id === Number(selectedCategory))?.subCategory || [];

  // console.log('subCategories' + subCategories);


  const fetchProductData = async () => {
    try {
      const response = await apiRequest({
        route: `products/${id}.json`,
        method: 'GET',
      });

      setValues({
        image: response.data.image,
        title: response.data.title,
        description_courte: response.data.description_courte,
        description: response.data.description,
        categorie: response.data.categorie[0].id,
        subcategorie: response.data.categorie[1] ? response.data.categorie[1].id : '',
        marque: response.data.marque.id,
        tarif_regulier: response.data.tarif_regulier,
        montant_de_stock_faible: response.data.montant_de_stock_faible,
        stock: response.data.stock,
        en_stock: response.data.en_stock,
        etat_de_la_tva: response.data.etat_de_la_tva,
        classe_de_tva: response.data.classe_de_tva,
        poids_kg: response.data.poids_kg,
        longueur_cm: response.data.longueur_cm,
        largeur_cm: response.data.largeur_cm,
        hauteur_cm: response.data.hauteur_cm,
        autoriser_les_avis_clients: response.data.autoriser_les_avis_clients,
        // limite_de_telechargement: response.data.limite_de_telechargement,
        // jours_dexpiration_du_telechargement: response.data.jours_dexpiration_du_telechargement,
        video_url: response.data.video_url,
      })

      setProducts(response.data);
      setSelectedCategory(response.data.categorie[0].id);
      // setDescriptionCourte(response.data.description_courte);
      // console.log(response.data);


    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchProductData();
  }, [])

  // console.log(products);


  const formik = useFormik({
    initialValues: {
      image: "",
      title: "",
      description_courte: "",
      description: "",
      categorie: "",
      subcategorie: "",
      marque: "",
      tarif_regulier: "",
      montant_de_stock_faible: "",
      stock: "",
      en_stock: "",
      etat_de_la_tva: "",
      classe_de_tva: "",
      poids_kg: "",
      longueur_cm: "",
      largeur_cm: "",
      hauteur_cm: "",
      autoriser_les_avis_clients: "",
      // limite_de_telechargement: "",
      // jours_dexpiration_du_telechargement: "",
      video_url: "",
    },

    // validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("productImages[]", files[i]);
      }

      // console.log(values)
      // console.log(imagefile)
      // console.log(files)

      formData.append('title', values.title);
      formData.append('description_courte', values.description_courte);
      formData.append('description', values.description);
      formData.append('subcategorie', values.subcategorie);
      formData.append('tarif_regulier', values.tarif_regulier);
      formData.append('stock', values.stock);
      formData.append('montant_de_stock_faible', values.montant_de_stock_faible);
      formData.append('poids_kg', values.poids_kg);
      formData.append('longueur_cm', values.longueur_cm);
      formData.append('largeur_cm', values.largeur_cm);
      formData.append('hauteur_cm', values.hauteur_cm);
      // formData.append('limite_de_telechargement', values.limite_de_telechargement);
      // formData.append('jours_dexpiration_du_telechargement', values.jours_dexpiration_du_telechargement);
      formData.append('newImageFile', newImagefile);
      formData.append('category', selectedCategory);
      formData.append('marque', values.marque);
      formData.append('video_url', values.video_url);

      try {
        const response = await apiRequest({
          route: `update-product/${id}`,
          method: 'POST',
          body: formData
        });

        if (response.status === 200) {
          UpdateNotify('Produit');
          fetchProductData();
          setNewImageFile(null);
          setFiles([]);
          resetForm();
        }
        // console.log(formData);

        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }

      // resetForm()
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues
  } = formik;

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setNewImage(URL.createObjectURL(e.target.files[0]));
      setNewImageFile(e.target.files[0]);
    }
  };

  // const handleMultiImageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setMultiImageFile(URL.createObjectURL(e.target.files[0]));
  //     setNewMultiImage(e.target.files[0]);
  //   }
  // };

  const handleMultiImageChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.files && e.target.files[0]) {
      const updatedImages = [...multiImages];
      updatedImages[index] = e.target.files[0];
      setMultiImages(updatedImages);
      setNewMultiImage(e.target.files[0]);
    }
  };


  const DeleteImage = async (imageId: number) => {
    try {
      const response = await apiRequest({
        route: `product_images/${imageId}`,
        method: 'DELETE',
      });

      if (response.status === 204) {
        DeleteNotify('Image');
        fetchProductData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateImage = async (imageId: number, index: number) => {
    try {
      if (multiImages[index] === undefined) {
        NoImageSelectedNotify();

      }
      else {
        const formData = new FormData();
        formData.append('newMultiImage', newMultiImage);
        const response = await apiRequest({
          route: `update-product-images/${imageId}`,
          method: 'POST',
          body: formData
        });

        if (response.status === 200) {
          UpdateNotify('Image');
          fetchProductData();
          setNewMultiImage(null);

        }

      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#product'
          aria-expanded='true'
          aria-controls='product'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Modifier Un Produit</h3>
          </div>
        </div>

        <div id='product' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Nom de produit</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Nom de produit'
                    name='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.title}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 col-md-6 fv-row d-none'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Description'
                    name='description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-12 col-md-12 fv-row">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description Courte</label>

                  <textarea
                    className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                    // data-kt-autosize='true'
                    rows={6}
                    placeholder='Description Courte'
                    name='description_courte'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description_courte}

                  ></textarea>
                </div>
              </div>

              <div className="row mb-6">
                <div className="col-lg-12 col-md-12 fv-row">
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                  <textarea
                    className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                    rows={6}
                    placeholder='description'
                    name='description'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}


                  ></textarea>


                  {/* <ReactQuill  
      className="react-quill-editor" 
      theme="snow" 
      value={descriptionCourte} 
      onChange={setDescriptionCourte} 
      modules={QuillModules} 
      /> */}
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Marque</label>
                  <select
                    name="marque"
                    className="form-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.marque}
                  >
                    <option value="" disabled>Marque</option>
                    {marques.map((marque: any, index) => {
                      return (
                        <option key={index} value={marque.id}>
                          {marque.title}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Categorie</label>
                  <select
                    name="categorie"
                    className="form-select"
                    // value={selectedCategory}
                    // onChange={handleCategoryChange}
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    value={selectedCategory ? selectedCategory : values.categorie}
                    onChange={handleCategoryChange}
                  // value={selectedCategory}
                  >
                    <option value="" disabled>Categorie</option>
                    {categories.map((categorie: any, index) => {
                      return (
                        <option key={index} value={categorie.id}>
                          {categorie.title}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Sous Categorie</label>
                  <select
                    name="subcategorie"
                    className="form-select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subcategorie}
                  // onChange={handleCategoryChange}
                  // value={selectedCategory}
                  >
                    <option value="" disabled>Sous Categorie</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.id} value={subCategory.id}>
                        {subCategory.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Prix de produit</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Prix de produit'
                    name='tarif_regulier'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tarif_regulier}
                  />
                  {formik.touched.tarif_regulier && formik.errors.tarif_regulier && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.tarif_regulier}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Montant de stock faible</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Montant de stock faible'
                    name='montant_de_stock_faible'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.montant_de_stock_faible}
                  />
                  {formik.touched.montant_de_stock_faible && formik.errors.montant_de_stock_faible && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.montant_de_stock_faible}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Stock</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Stock'
                    name='stock'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stock}
                  />
                  {formik.touched.stock && formik.errors.stock && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.stock}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Poids Kg</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Poids Kg'
                    name='poids_kg'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.poids_kg}
                  />
                  {formik.touched.poids_kg && formik.errors.poids_kg && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.poids_kg}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Longueur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Longueur cm'
                    name='longueur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.longueur_cm}
                  />
                  {formik.touched.longueur_cm && formik.errors.longueur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.longueur_cm}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Largeur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Largeur cm'
                    name='largeur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.largeur_cm}
                  />
                  {formik.touched.largeur_cm && formik.errors.largeur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.largeur_cm}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-3 col-md-6 fv-row'>
                  <label className='col-lg-12 col-md-12 col-form-label fw-bold fs-6'>Hauteur cm</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Hauteur cm'
                    name='hauteur_cm'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hauteur_cm}
                  />
                  {formik.touched.hauteur_cm && formik.errors.hauteur_cm && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.hauteur_cm}</div>
                    </div>
                  )}
                </div>
              </div>
{/* 
              <div className='row mb-6'>
                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Limite de_telechargement</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='LimLte de telechargement'
                    name='limite_de_telechargement'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.limite_de_telechargement}
                  />
                  {formik.touched.limite_de_telechargement && formik.errors.limite_de_telechargement && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.limite_de_telechargement}</div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 col-md-6 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Jours dexpiration du telechargement</label>

                  <input
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Jours dexpiration du telechargement'
                    name='jours_dexpiration_du_telechargement'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.jours_dexpiration_du_telechargement}
                  />
                  {formik.touched.jours_dexpiration_du_telechargement && formik.errors.jours_dexpiration_du_telechargement && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.jours_dexpiration_du_telechargement}</div>
                    </div>
                  )}
                </div>
              </div> */}

              <div className='row mb-6'>

                {/* Images */}



                <div className='col-lg-4 col-md-4 fv-row'>

                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Ajouté Autre Images</label>

                  <input
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const { files } = e.target;
                      if (files) {
                        setFiles(Array.from(files));
                      }
                    }}
                    type="file"
                    name="productImages"
                    accept=".png, .jpg, .jpeg"
                    className='form-control form-control-lg form-control-solid'
                    multiple
                  />
                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Nouveau Image</label>
                  <input
                    type="file"
                    name="image"
                    accept=".png, .jpg, .jpeg, .webp"
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    onChange={handleImageChange} />

                  {newImagefile ? (
                    <div className='d-flex align-items-center position-absolute'>
                      <div className='symbol symbol-70px'>
                        <img src={URL.createObjectURL(newImagefile)} alt=''
                          className='w-100 shadow rounded mt-3' style={{ height: '140px' }} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                </div>

                <div className='col-lg-4 col-md-4 fv-row'>
                  <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Lien de Video</label>

                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Lien de Video'
                    name='video_url'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.video_url}
                  />
                  {formik.touched.video_url && formik.errors.video_url && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.video_url}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>l'Image Actuelle</label>

                <div className='d-flex align-items-center'>
                  <div className='symbolsymbol-70px old-image'>
                    <img
                      src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${values.image}`}
                      className='w-100 shadow rounded' style={{ height: '140px' }}
                      alt=''
                    />
                  </div>
                </div>

              </div>

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Enregistrer les modifications'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="card mb-5 mb-xl-10">
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#sub'
          aria-expanded='true'
          aria-controls='sub'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>List Des Images</h3>
          </div>
        </div>
        <div id='sub' className='collapse show'>
          {/* <form onSubmit={subFormik.handleSubmit} noValidate className='form'> */}

          <div className='card-body border-top p-9 d-flex justify-content-start flex-wrap'>

            {
              products?.productImages.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="img-card shadow rounded mx-2 mb-5" >
                      <div className="edit-parent d-flex justify-content-between">
                        <div className="delete-img" onClick={() => DeleteImage(item.id)}>
                          <i className='bi bi-trash-fill fs-1 del-icon'></i>
                        </div>
                        <div className="update-img" onClick={() => UpdateImage(item.id, item.id)} >
                          <i className='bi bi-pen-fill fs-4 update-icon'></i>
                        </div>
                      </div>
                      <div className="img-parent w-100">
                        {

                          multiImages[item.id] ?
                            <img
                              key={item.id}
                              src={URL.createObjectURL(multiImages[item.id])}
                              alt={`img-${item.id}`}
                              className='w-100 multi-img rounded'
                            />
                            :
                            <img
                              key={item.id}
                              src={`${process.env.REACT_APP_API_UPLOAD}/product_multi_images/${item.image}`}
                              alt={`img-${item.id}`}
                              className='w-100 multi-img rounded'
                            />
                        }

                      </div>
                      <div className="action pos-r fixed-bottom d-flex justify-content-center">
                        {/* <input
                          type="file"
                          name="multiImage"
                          accept=".png, .jpg, .jpeg, .webp"
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          onChange={handleMultiImageChange}
                          // value={newi}
                        /> */}
                        <input
                          type="file"
                          name={`multiImage-${item.id}`}
                          accept=".png, .jpg, .jpeg, .webp"
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          onChange={(e) => handleMultiImageChange(e, item.id)}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              )}

            {/* end::Table container */}


          </div>
          {/* </form> */}

        </div>
      </div>

    </>
  )
}

export default UpdateProduct