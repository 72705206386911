import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddingNotify } from '../../../_metronic/helpers/Toastify';
import { ProductProps } from '../../../_metronic/helpers/Props';

const Schema = Yup.object().shape({
  discount: Yup.number()
    .min(1, 'La remise doit être d\'au moins 1%')
    .max(100, 'La remise ne peut pas dépasser 100%')
    .required('Ce champ est obligatoire')
    .typeError('Veuillez entrer un nombre valide'),
  status: Yup.number().oneOf([0, 1], 'Invalid value'),
  dateDebut: Yup.date().required('Ce champ est obligatoire'),
  dateFin: Yup.date().required('Ce champ est obligatoire'),
});


const AddPromotion = () => {

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const apiRequest = useApiRequest()
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const animatedComponents = makeAnimated();


  const fetchProductsData = async () => {
    try {
      const response = await apiRequest({
        route: 'products-by-price',
        method: 'GET',
      });

      setProducts(response.data.products)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, [])


  const options = products.map((product: any) => ({
    value: `api/products/${product.id}`,
    label: `${product.title} | ${product.tarif_regulier} DH`,
  }));

  const handleChangeSelect = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => (option.value));
    setSelectedProducts(selectedValues);
  };

  const formik = useFormik({
    initialValues: {
      description: '',
      discount: '',
      // status: 1,
      dateDebut: '',
      dateFin: '',
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {
      const data = { ...values, status: status, product: selectedProducts };

      try {

        const response = await apiRequest({
          route: `promotions`,
          method: 'POST',
          body: data
        });

        if (response.status === 201) {
          AddingNotify('Promotion')
        }


        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }

      resetForm();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Promotion</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>
                  Description
                </label>
                <input
                  type='text'
                  name='description'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                />
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Remise
                </label>
                <input
                  type='number'
                  min={1}
                  max={100}
                  name='discount'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Remise'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.discount}
                />
                {touched.discount && errors.discount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.discount}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Statut de promotion
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='status'
                    id='active'
                    className='form-check-input'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 col-form-label fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='status'
                    id='desactive'
                    className='form-check-input'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 col-form-label fw-bold'>
                    Desactive
                  </label>

                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Date de debut de promotion
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de promotion'
                  min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateDebut}
                />
                {touched.dateDebut && errors.dateDebut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateDebut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Date de fin de promotion
                </label>
                <input
                  type='date'
                  name='dateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de promotion'
                  min={values.dateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateFin}
                  disabled={!values.dateDebut}

                />
                {touched.dateFin && errors.dateFin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateFin}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Produits
                </label>

                {
                  products.length > 0 ?
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={options}
                      onChange={handleChangeSelect}
                    // isDisabled={checked}
                    />
                    :
                    ''
                }

              </div>

            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPromotion;