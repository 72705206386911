import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useApiRequest } from '../../../_metronic/helpers/ApiRequest';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddingNotify } from '../../../_metronic/helpers/Toastify';
import { ProductProps } from '../../../_metronic/helpers/Props';

const Schema = Yup.object().shape({
  title: Yup.string().required('Ce champ est obligatoire'),
  tarif_regulier: Yup.number().required('Ce champ est obligatoire')
    .typeError('Veuillez entrer un nombre valide'),
  status: Yup.number().oneOf([0, 1], 'Invalid value'),
  dateDebut: Yup.date().required('Ce champ est obligatoire'),
  dateFin: Yup.date().required('Ce champ est obligatoire'),
});


const AddPack = () => {

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(1);
  const apiRequest = useApiRequest()
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const animatedComponents = makeAnimated();
  const [imagefile, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");

  const [selectedValue, setSelectedValue] = useState<number>(0);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(Number(event.target.value));
  };

  const fetchProductsData = async () => {
    try {
      const response = await apiRequest({
        route: 'products.json',
        method: 'GET',
      });

      setProducts(response.data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProductsData();
  }, [])


  // value: `api/products/${product.id}`,value: product.id,
  const options = products.map((product: any) => ({
    value: product.id,
    label: `${product.title} | ${product.tarif_regulier} DH`,
  }));

  const handleChangeSelect = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => (option.value));
    setSelectedProducts(selectedValues);
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      tarif_regulier: '',
      dateDebut: '',
      dateFin: '',
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm }) => {

      const formData = new FormData();

      for (let i = 0; i < selectedProducts.length; i++) {
        formData.append("packItem[]", selectedProducts[i]);
      }

      formData.append('itemsQuantity', String(selectedProducts.length));
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('tarif_regulier', values.tarif_regulier);
      formData.append('status', String(status));
      formData.append('dateDebut', values.dateDebut);
      formData.append('dateFin', values.dateFin);
      formData.append('imageFile', imagefile);


      try {

        const response = await apiRequest({
          route: `packs`,
          method: 'POST',
          body: formData
        });

        if (response.status === 201) {
          AddingNotify('Pack')
        }

        console.log(formData);
        

        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      }

      // resetForm();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;


  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#product'
        aria-expanded='true'
        aria-controls='product'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Ajouter Un Pack</h3>
        </div>
      </div>

      <div id='product' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Titre de Pack
                </label>
                <input
                  type='text'
                  name='title'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Titre de Pack'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                />
                {touched.title && errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.title}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Prix
                </label>
                <input
                  type='number'
                  name='tarif_regulier'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Prix'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tarif_regulier}
                />
                {touched.tarif_regulier && errors.tarif_regulier && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.tarif_regulier}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>
                  Statut de pack
                </label>
                <div className='form-check form-check-custom form-check-solid form-check-info'>
                  <input
                    type='radio'
                    name='status'
                    id='active'
                    className='form-check-input'
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                    onBlur={handleBlur}
                    value={1}
                  />
                  <label htmlFor='active' className='ms-2 col-form-label fw-bold'>
                    Active
                  </label>

                  <input
                    type='radio'
                    name='status'
                    id='desactive'
                    className='form-check-input'
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                    onBlur={handleBlur}
                    value={0}
                  />
                  <label htmlFor='desactive' className='ms-2 col-form-label fw-bold'>
                    Desactive
                  </label>

                  {/* {touched.status && errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.status}</div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Date de debut de pack
                </label>
                <input
                  type='date'
                  name='dateDebut'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de debut de pack'
                  min={new Date().toISOString().split('T')[0]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateDebut}
                />
                {touched.dateDebut && errors.dateDebut && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateDebut}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Date de fin de pack
                </label>
                <input
                  type='date'
                  name='dateFin'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Date de fin de pack'
                  min={values.dateDebut}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateFin}
                  disabled={!values.dateDebut}

                />
                {touched.dateFin && errors.dateFin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.dateFin}</div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-4 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label required fw-bold fs-6'>Image</label>

                <input
                  type="file"
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {

                    if (e.target.files && e.target.files[0]) {
                      setFile(URL.createObjectURL(e.target.files[0]));
                      setImage(e.target.files[0]);
                    }
                  }} />
                {formik.values.title && imagefile === null && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>L'image est obligatoire!</div>
                  </div>
                )}

              </div>


            </div>

            <div className='row mb-6'>
              <div className='col-lg-6 col-md-6 fv-row'>
                <label className='col-lg-8 col-md-8 col-form-label required fw-bold fs-6'>
                  Liste Des Produits
                </label>

                {
                  products.length > 0 ?
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={options}
                      onChange={handleChangeSelect}
                    />
                    :
                    ''
                }

              </div>
            </div>

            <div className='row mb-6'>
              <div className='col-lg-12 col-md-12 fv-row'>
                <label className='col-lg-6 col-md-6 col-form-label fw-bold fs-6'>Description</label>

                <textarea
                  className='form-control border-1 p-0 ps-3 pt-1 pe-10 resize-none min-h-25px'
                  rows={6}
                  placeholder='description'
                  {...formik.getFieldProps('description')}

                ></textarea>


              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Enregistrer les modifications'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPack;