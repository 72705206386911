import { toast } from 'react-toastify';


export function LoginRequireNotify () {
    toast.info("Vous devrez être connecté pour effectuer cette action!");
};

export function CodePromoApplyNotify () {
    toast.success("Code promo appliqué avec succès");
};

export function CodePromoExpireNotify () {
    toast.error("Ce code promo est expiré");
};

export function CodePromoNotFountNotify () {
    toast.error("Code promo n'existe pas");
};

export function CodePromoAlreadyApplyNotify () {
    toast.info("Code promo dejà appliqué");
};

export function CodePromoDeleteNotify () {
    toast.info("Code promo bien supprimé");
};

export function AddReviewNotify () {
    toast.success("Avis bien envoyé");
};

export function AddAdresseNotify () {
    toast.success("Adresse bien ajouté");
};

export function EditAdresseNotify () {
    toast.success("Adresse bien modifié");
};

export function ValidateAdresseNotify () {
    toast.success("Adresse bien validé");
};

export function AddOrderNotify () {
    toast.success("Commmane bien passé");
};

export function AddAdresseRequireNotify () {
    toast.info("Veuillez ajouter votre adresses");
};

export function AddingNotify (entity:string) {
    toast.success(`${entity} ajouté avec succès`);
};

export function UpdateNotify (entity:string) {
    toast.success(`${entity} modifié avec succès`);
};

export function DeleteNotify (entity:string) {
    toast.success(`${entity} supprimé avec succès`);
};

export function NoImageSelectedNotify () {
    toast.error(`Aucune image sélectionnée`);
};

export function notify (text:string) {
    toast.success(`${text}`);
};

export function errorNotify (text:string) {
    toast.error(`${text}`);
};

// interface ToastNotifyProps {
//     message: string;
//     type: "success" | "error" | "info" | "warning";
// }

// export function ToastNotify ({message, type}: ToastNotifyProps) {
//     return `${toast}.${type}(${message})`
// };